import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingOrderReportService {    
    readPurchasingOrderReport(){
        var query = `query ($startDate:String!, $endDate:String!, $itemId:String, $supplierId:Int) {
            GetPurchasingPurchaseOrderReport (start_date:$startDate, end_date:$endDate, item_id:$itemId, supplier_id:$supplierId) {
                summary {
                    quantity_per_status {
                        New
                        Revisi
                        Approved
                        Ready
                        Pending
                        Checking
                        Finish
                        Delete
                    }
                    total_vat_per_status {
                        New
                        Revisi
                        Approved
                        Ready
                        Pending
                        Checking
                        Finish
                        Delete
                    }
                }
                detail {
                    purchase_order_number
                    status
                    request_id
                    request_number
                    purchase_order_date
                    ppn_tax_percentage
                    supplier_id
                    supplier_code
                    supplier_name
                    currency_id
                    currency_name
                    exchange_rate
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    created_by
                    created_at
                    last_update
                    purchase_order_id
                    item_id
                    item_name
                    product_code
                    barcode
                    type_detail_id
                    uom
                    discount
                    request_quantity
                    ordered_quantity
                    remaining_quantity
                    quantity
                    delivered_quantity
                    price_per_unit
                    price
                    vat_per_unit
                    vat
                }
            }
        }`;
        return query;
    }

    async getPurchasingOrderReport(variables) {
        var query = gql`query($startDate:String!,$endDate:String!, $itemId:String, $supplierId:Int) {
            GetPurchasingPurchaseOrderReport(start_date:$startDate,end_date:$endDate, item_id:$itemId, supplier_id:$supplierId){
                summary {
                    quantity_per_status {
                        New
                        Revisi
                        Approved
                        Ready
                        Pending
                        Checking
                        Finish
                        Delete
                    }
                    total_vat_per_status {
                        New
                        Revisi
                        Approved
                        Ready
                        Pending
                        Checking
                        Finish
                        Delete
                    }
                }
                detail {
                    purchase_order_number
                    status
                    request_id
                    request_number
                    purchase_order_date
                    ppn_tax_percentage
                    supplier_id
                    supplier_code
                    supplier_name
                    currency_id
                    currency_name
                    exchange_rate
                    notes
                    printed_notes
                    address
                    attachment
                    down_payment
                    created_by
                    created_at
                    last_update
                    purchase_order_id
                    item_id
                    item_name
                    product_code
                    barcode
                    type_detail_id
                    uom
                    discount
                    request_quantity
                    ordered_quantity
                    remaining_quantity
                    quantity
                    delivered_quantity
                    price_per_unit
                    price
                    vat_per_unit
                    vat
                }
            }
        }`    
        var result =  await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetPurchasingPurchaseOrderReport;
    }
    
    async getItemQuery(){
        var query = gql`
            query {
                GetProductMaster{
                    ProductMaster{
                        item_id
                        product_code
                        item_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetProductMaster.ProductMaster;
        var itemData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { 
                value: data[i].item_id, 
                label: data[i].product_code + ' (' + data[i].item_name + ')'
            }
            itemData.push(str);
        }

        return itemData;
    }

    async getSupplierQuery(){
        var query = gql`
            query {
                GetContact (ContactType:"Vendor") {
                    contact_id
                    contact_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { 
                    value: result.data.GetContact[i].contact_id, 
                    label: result.data.GetContact[i].contact_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }
    
    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    purchase_order_number: data[i].purchase_order_number,
                    status: data[i].status,
                    request_number: data[i].request_number,
                    purchase_order_date: kendo.toString(kendo.parseDate(data[i].purchase_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    ppn_tax_percentage: data[i].ppn_tax_percentage,
                    supplier_name: data[i].supplier_name,
                    currency_name: data[i].currency_name,
                    exchange_rate: data[i].exchange_rate,
                    notes: data[i].notes,
                    printed_notes: data[i].printed_notes,
                    address: data[i].address,
                    down_payment: data[i].down_payment,
                    purchase_order_number: data[i].purchase_order_number,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    purchasingOrderReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
            {width: 20}, //colR
            {width: 20}, //colS
            {width: 20}, //colT
            {width: 20}, //colU
            {width: 20}, //colV
            {width: 20}, //colW
            {width: 20}, //colX
            {width: 20}, //colY
            {width: 20}, //colZ
            {width: 20}, //colAA
            {width: 20}, //colAB
            {width: 20}, //colAC

        ];
        return size;
    }

    purchasingOrderReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'PURCHASING REPORT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'STATUS',
            colB : 'NO. PO',
            colC : 'TGL. PO',
            colD : 'NO. REQUEST',
            colE : 'VENDOR',
            colF : 'CATATAN',
            colG : 'PPN %',
            colH : 'MATA UANG',
            colI : 'EXCHANGE RATE',
            colJ : 'KODE BARANG',
            colK : 'NAMA BARANG',
            colL : 'SATUAN',
            colM : 'REQUEST QUANTITY',
            colN : 'ORDERED QUANTITY',
            colO : 'REMAINING QUANTITY',
            colP : 'QUANTITY',
            colR : 'RECEIVED QUANTITY',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].purchase_order_number,
                    colC : kendo.toString(kendo.parseDate(data[i].purchase_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    colD : data[i].request_number,
                    colE : data[i].supplier_name,
                    colF : data[i].notes,
                    colG : data[i].ppn_tax_percentage,
                    colH : data[i].currency_name,
                    colI : data[i].exchange_rate,
                    colJ : data[i].product_code,
                    colK : data[i].item_name,
                    colL : data[i].uom,
                    colM : data[i].request_quantity,
                    colN : data[i].ordered_quantity,
                    colO : data[i].remaining_quantity,
                    colP : data[i].quantity,
                    colR : data[i].delivered_quantity,
                };
                
                arrayObject.push(row);
            }
        }

        // arrayObject.push(spaceRow);

        // var row = { colA : '',
        //             colB : 'TOTAL',
        //             colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},};
        // arrayObject.push(row);

        return arrayObject;
    }
}

export default new PurchasingOrderReportService();