import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ProductionReportService {
    readQuery(){
        var query = `query($startDate: String!, $endDate: String!){ 
            GetProductionReport(start_date:$startDate, end_date:$endDate){
                production_id
                production_code
                production_type
                status
                production_result_item_id
                production_result_item_name
                production_result_product_code
                production_result_quantity
                production_result_uom
                created_at
                last_update
                waste_percentage
                storage_id
                machine_id
                machine_name
                booking_order_id
                producer_id
                producer_name
                production_actual_quantity
                production_reject_item_id
                production_reject_quantity
                production_start_date
                production_finish_date
                production_start_date_est
                production_finish_date_est
                production_material {
                    production_id
                    item_id
                    item_name
                    product_code
                    type_detail_id
                    uom
                    quantity
                    waste_quantity
                    actual_quantity
                    formula_id
                    barcode
                    item_type
                    active_flag
                    required_type_detail_quantity
                    created_at
                    last_update
                    cost {
                    production_id
                    item_id
                    price_id
                    quantity
                    created_at
                    last_update  
                    }
                }
                production_cost
            }
        }`;
        return query;
    }

    async getProductionReportQuery(variables){
        var query = gql`
            query ($startDate: String!, $endDate: String!) {
                GetProductionReport(start_date:$startDate, end_date:$endDate){
                    production_id
                    production_code
                    production_type
                    status
                    production_result_item_id
                    production_result_item_name
                    production_result_product_code
                    production_result_quantity
                    production_result_uom
                    created_at
                    last_update
                    waste_percentage
                    storage_id
                    machine_id
                    machine_name
                    booking_order_id
                    producer_id
                    producer_name
                    production_actual_quantity
                    production_reject_item_id
                    production_reject_quantity
                    production_start_date
                    production_finish_date
                    production_start_date_est
                    production_finish_date_est
                    production_material {
                        production_id
                        item_id
                        item_name
                        product_code
                        type_detail_id
                        uom
                        quantity
                        waste_quantity
                        actual_quantity
                        formula_id
                        barcode
                        item_type
                        active_flag
                        required_type_detail_quantity
                        created_at
                        last_update
                        cost {
                            production_id
                            item_id
                            price_id
                            quantity
                            created_at
                            last_update  
                        }
                    }
                    production_cost
                }
            }`;
    
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProductionReport;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    production_code: data[i].production_code,
                    production_start_date: kendo.toString(kendo.parseDate(data[i].production_start_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    status: data[i].status,
                    production_reject_quantity: data[i].production_reject_quantity,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    productionReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
        ];

        return size;
    }
    productionReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'Report Produksi',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row5 = {colA : 'ID',
                    colB : 'Status',
                    colC : 'Total Pembuangan',
                    colD : 'Total Produksi',
                    colE : 'Jumlah Material',
                    colF : 'Biaya Produksi'};
        arrayObject.push(row5);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : {v: data[i].production_id, t:'s'},
                    colB : data[i].status,
                    colC : { v: data[i].total_waste, t: 'n' , z: numberFormat},
                    colD : { v: data[i].total_produce, t: 'n' , z: numberFormat},
                    colE : { v: data[i].total_material_used, t: 'n' , z: numberFormat},
                    colF : { v: data[i].production_cost, t: 'n' , z: numberFormat}};
                arrayObject.push(row);
            }
        }

        arrayObject.push(spaceRow);

        var row = { colA : '',
            colB : 'TOTAL',
            colD : {f: '=SUM(D'+ startIndex.toString() +':D'+ lastIndex.toString() +')' , t:'n', z: numberFormat},
            colE : {f: '=SUM(E'+ startIndex.toString() +':E'+ lastIndex.toString() +')' , t:'n', z: numberFormat},
            colF : {f: '=SUM(F'+ startIndex.toString() +':F'+ lastIndex.toString() +')' , t:'n', z: numberFormat}};
        arrayObject.push(row);

        return arrayObject;
    }
}


export default new ProductionReportService();