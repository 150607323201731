import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceReportService {    
    readPurchasingInvoicePaymentReport(){
        var query = `query($startDate:String!,$endDate:String!) {
            GetPurchasingInvoicePaymentReport(start_date:$startDate, end_date:$endDate){
                summary{
                    quantity_per_status{
                      new
                      unclear
                      clear
                      delete
                    }
                    total_payment_per_status{
                      new
                      unclear
                      clear
                      delete
                    }
                }
                detail {
                    created_at
                    last_update
                    inv_payment_id
                    inv_payment_number
                    inv_payment_type
                    status
                    inv_payment_date
                    payment_method
                    bank
                    branch
                    account_number
                    account_name
                    round_up
                    delivery_fee
                    admin_fee
                    other_fee
                    supplier_id
                    supplier_name
                    currency_id
                    invoice_id
                    invoice_date
                    invoice_number
                    tax_invoice
                    invoice_total
                    notes
                    total_payment
                    paid
                    left
                }
            }
        }`;
        return query;
    }

    async getPurchasingInvoicePaymentReport(variables) {
        var query = gql`query($startDate:String!,$endDate:String!) {
            GetPurchasingInvoicePaymentReport(start_date:$startDate, end_date:$endDate){
                summary{
                    quantity_per_status{
                        new
                        unclear
                        clear
                        delete
                    }
                    total_payment_per_status{
                        new
                        unclear
                        clear
                        delete
                    }
                }
                detail {
                    created_at
                    last_update
                    inv_payment_id
                    inv_payment_number
                    inv_payment_type
                    status
                    inv_payment_date
                    payment_method
                    bank
                    branch
                    account_number
                    account_name
                    round_up
                    delivery_fee
                    admin_fee
                    other_fee
                    supplier_id
                    supplier_name
                    currency_id
                    invoice_id
                    invoice_date
                    invoice_number
                    tax_invoice
                    invoice_total
                    notes
                    total_payment
                    paid
                    left
                }
            }
        }`    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetPurchasingInvoicePaymentReport
    }
    purchasingInvoicePaymentReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
            {width: 20}, //colR
            {width: 20}, //colS
            {width: 20}, //colT
            {width: 20}, //colU
            {width: 20}, //colV
            {width: 20}, //colW
            {width: 20}, //colX
            {width: 20}, //colY
            {width: 20}, //colZ
            {width: 20}, //colAA
            {width: 20}, //colAB
            {width: 20}, //colAC

        ];
        return size;
    }

    purchasingInvoicePaymentReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'PURCHASING INVOICE PAYMENT REPORT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'STATUS',
            colB : 'NO PAYMENT INVOICE',
            colC : 'TIPE PAYMENT INVOICE',
            colD : 'TGL. PAYMENT INVOICE',
            colE : 'METODE PEMBAYARAN',
            colF : 'BANK',
            colG : 'NAMA AKUN',
            colH : 'CABANG',
            colI : 'CATATAN',
            colJ : 'VENDOR',
            colK : 'NO. INVOICE',
            colL : 'NO. FAKTUR PAJAK',
            colM : 'TGL. INVOICE',
            colN : 'NILAI INVOICE',
            colO : 'TERBAYAR',
            colP : 'JUMLAH BAYAR',
            colQ : 'SISA',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].inv_payment_number,
                    colC : data[i].inv_payment_type,
                    colD : kendo.toString(kendo.parseDate(data[i].inv_payment_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                    colE : data[i].payment_method,
                    colF : data[i].bank,
                    colG : data[i].account_name,
                    colH : data[i].branch,
                    colI : data[i].notes,
                    colJ : data[i].supplier_name,
                    colK : data[i].invoice_number,
                    colL : data[i].tax_invoice,
                    colM : kendo.toString(kendo.parseDate(data[i].invoice_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                    colN : { v: data[i].invoice_total, t: 'n', z: numberFormat },
                    colO : { v: data[i].paid, t: 'n', z: numberFormat },
                    colP : { v: data[i].total_payment, t: 'n', z: numberFormat },
                    colQ : { v: data[i].left, t: 'n', z: numberFormat },
                };
                
                arrayObject.push(row);
            }
        }

        // arrayObject.push(spaceRow);

        // var row = { colA : '',
        //             colB : 'TOTAL',
        //             colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},};
        // arrayObject.push(row);

        return arrayObject;
    }
}

export default new PurchasingInvoiceReportService();