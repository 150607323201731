import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction';
import moment from 'moment';

class PurchasingRequestReportService {  
    readPurchasingRequestReport() {
        var query = `
        query ($startDate:String!, $endDate:String!) {
            GetPurchasingRequestReport (start_date:$startDate, end_date:$endDate){
                summary{
                    quantity_per_status{
                        New
                        Revisi
                        Approved
                        Ready
                        Pending
                        Checking
                        Finish
                        Delete
                    }
                }
                detail{
                    request_number
                    status
                    request_date
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    created_at
                    last_update
                    request_id
                    item_id
                    item_name
                    product_code
                    barcode
                    type_detail_id
                    uom
                    quantity
                    ordered_quantity
                    delivered_quantity
                }
            }
        }
    `;
        return query;
    }

    async getPurchasingRequestReport(variables) {
        var query = gql`query($startDate:String!,$endDate:String!) {
            GetPurchasingRequestReport(start_date:$startDate,end_date:$endDate){
                summary{
                    quantity_per_status{
                        New
                        Revisi
                        Approved
                        Ready
                        Pending
                        Checking
                        Finish
                        Delete
                    }
                }
                detail{
                    request_number
                    status
                    request_date
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    created_at
                    last_update
                    request_id
                    item_id
                    item_name
                    product_code
                    barcode
                    type_detail_id
                    uom
                    quantity
                    ordered_quantity
                    delivered_quantity
                }
            }
        }`    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetPurchasingRequestReport;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    request_number: data[i].request_number,
                    status: data[i].status,
                    request_date: kendo.toString(kendo.parseDate(data[i].request_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    notes: data[i].notes,
                    printed_notes: data[i].printed_notes,
                    address: data[i].address,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    barcode: data[i].barcode,
                    uom: data[i].uom,
                    quantity: data[i].quantity,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    purchasingRequestReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 40}, //colN
            {width: 40}, //colO


        ];
        return size;
    }

    purchasingRequestReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'PURCHASING REPORT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'STATUS',
            colB : 'NO. REQUETS',
            colC : 'TGL. REQUEST',
            colD : 'ALAMAT',
            colE : 'CATATAN',
            colF : 'KODE BARANG',
            colG : 'NAMA BARANG',
            colH : 'SATUAN',
            colI : 'ORDERED QUANTITY',
            colJ : 'DELIVERED QUANTITY',
        }
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].request_number,
                    colC : kendo.toString(kendo.parseDate(data[i].request_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    colD : data[i].address,
                    colE : data[i].notes,
                    colF : data[i].product_code,
                    colG : data[i].item_name,
                    colH : data[i].uom,
                    colI : data[i].ordered_quantity,
                    colJ : data[i].delivered_quantity,
                }
                
                arrayObject.push(row);
            }
        }

        // arrayObject.push(spaceRow);

        // var row = { colA : '',
        //             colB : 'TOTAL',
        //             colC : {f: '=SUM(C'+ startIndex.toString() +':C'+ lastIndex.toString() +')' , t:'n', z: numberFormat},};
        // arrayObject.push(row);

        return arrayObject;
    }
}

export default new PurchasingRequestReportService();